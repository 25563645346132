// src/Components/Create/Members.jsx
// src/Components/Create/Members.jsx
import React, { useEffect, useState } from 'react';
import { Button, Modal, ModalBody, ModalHeader, Table, Dropdown, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { Formik, Form as FormikForm, Field, ErrorMessage } from 'formik';
import { FaTimes } from "react-icons/fa";
import { MdModeEdit } from "react-icons/md"; // Import edit icon
import * as Yup from 'yup';
import './Create.css'; // Ensure the path is correct
import { useDispatch, useSelector } from 'react-redux';
import { setMembers } from '../../actions'; // Correct path to actions
import appUrl from '../../appUrl';
import Select from 'react-select';


const Members = () => {
  const AppUrl = appUrl();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Retrieve members from Redux store
  const members = useSelector((state) => state.members);
  console.log(members, "members");

  const [memberModal, setMemberModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  // const [teams, setTeams] = useState([]);
  const [data, setData] = useState([]);
  const [selectedType, setSelectedType] = useState([]);
  const [errorMessage, setErrorMessage] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [currentEditIndex, setCurrentEditIndex] = useState(null);

  console.log(data, "dataaaa");

  const schema = Yup.object().shape({
    team_members_name: Yup.string().required('Participant name is required'),
    team_members_email: Yup.string().email('Invalid email').required('Participant email is required'),
    team_members_type: Yup.array().min(1, 'Select at least one participant type').required('Participant type is required')
  });

  const handleAddMembers = (values) => {
    // Find the team name based on team_id
    const selectedTeam = data.teams.find(team => team._id === values.team_id);
    const team_name = selectedTeam ? selectedTeam.team_name : '-';

        const selectedCategory = data?.special_category.find(cat => cat._id === values.category)
    const category_name = selectedCategory ? selectedCategory.title : "-"
    const category_id = selectedCategory ? selectedCategory._id : null;

    // Create the updated member object with team_name
    const updatedMember = {
      ...values,
      team_name, // Add team_name to the member object
      category_name,
      category: category_id,
    };

    // Append the new member to the existing members array
    const updatedMembers = [...members, updatedMember];
    console.log("Dispatching members Data:", updatedMembers);
    dispatch(setMembers(updatedMembers)); // Dispatch updated members
    setErrorMessage(false);
  };

  // Option component for custom display in dropdown
  const customOption = (props) => {
    const { data, innerRef, innerProps } = props;
    return (
      <div ref={innerRef} {...innerProps} className="category-option-display">
        <h6>{data.title}</h6>
        <p>{data.description}</p>
      </div>
    );
  };

  const handleUpdateMembers = (index, values) => {
    // Find the team name based on team_id
    const selectedTeam = data?.teams.find(team => team._id === values.team_id);
    const team_name = selectedTeam ? selectedTeam.team_name : '-';

    const selectedCategory = data?.special_category.find(cat => cat._id === values.category)
    const category_name = selectedCategory ? selectedCategory.title : "-"
    const category_id = selectedCategory ? selectedCategory._id : null;

    // Create the updated member object with team_name
    const updatedMember = {
      ...values,
      team_name,
      category_name,
      category: category_id,
    };

    // Update the specific member in the members array
    const updatedMembers = members.map((member, idx) => idx === index ? updatedMember : member);
    console.log("Dispatching Updated members Data:", updatedMembers);
    dispatch(setMembers(updatedMembers)); // Dispatch updated members
    setErrorMessage(false);
  };

  const handleDeleteRow = (index) => {
    const updatedMembers = members.filter((_, i) => i !== index); // Remove member at index
    console.log("Dispatching members Data after deletion:", updatedMembers);
    dispatch(setMembers(updatedMembers)); // Dispatch updated members
  };

  const handleEditRow = (index) => {
    setIsEditing(true);
    setCurrentEditIndex(index);
    const memberToEdit = members[index];
    setSelectedType(memberToEdit.team_members_type);
    setSelectedImage(memberToEdit.team_member_photo ? memberToEdit.team_member_photo : null);
    setMemberModal(true);
  };

  const initialFormValues = isEditing && currentEditIndex !== null && members[currentEditIndex]
    ? {
      team_members_name: members[currentEditIndex].team_members_name || "",
      team_member_photo: members[currentEditIndex].team_member_photo || "",
      team_members_email: members[currentEditIndex].team_members_email || "",
      team_members_type: members[currentEditIndex].team_members_type || [],
      category: members[currentEditIndex].category || "",
      team_id: members[currentEditIndex].team_id || "",
    }
    : {
      team_members_name: "",
      team_member_photo: "",
      team_members_email: "",
      team_members_type: [],
      category: "",
      team_id: ""
    };

  const handleImageChange = (e, setFieldValue) => {
    const file = e.target.files[0];
    if (file) {
      const blobUrl = URL.createObjectURL(file); // Create blob URL
      setSelectedImage(blobUrl); // Show selected image preview
      setFieldValue('team_member_photo', blobUrl); // Store blob URL in Formik state
    }
  };

  const handleImageDelete = (setFieldValue) => {
    setSelectedImage(null); // Clear selected image preview
    setFieldValue('team_member_photo', ""); // Clear Formik image field
  };

  const handleTeamAndCategorySelection = async () => {
    try {
      const fishingToken = localStorage.getItem('token');
      const tournamentId = localStorage.getItem("tournament");
      const response = await fetch(`${AppUrl}/api/v1/tournament/all-teams?tournamentId=${tournamentId}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${fishingToken}`,
          'Content-Type': 'application/json',
        }
      });
      const data = await response.json();
      if (data.code === 200 || data.status === true) {
        console.log("Teams fetched successfully");
        setData(data.data); // Set data
      } else {
        console.error('Failed to fetch data');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  useEffect(() => {
    handleTeamAndCategorySelection();
  }, []);

  // Handle toggling participant types
  const handleOptionToggle = (value, setFieldValue) => {
    let updatedSelectedType = [...selectedType];

    if (updatedSelectedType.includes(value)) {
      // If already selected, remove it
      updatedSelectedType = updatedSelectedType.filter(item => item !== value);
    } else {
      // If selecting 'guide' and 'angler' is already selected, remove 'angler'
      if (value === 'guide' && updatedSelectedType.includes('angler')) {
        updatedSelectedType = updatedSelectedType.filter(item => item !== 'angler');
      }
      // If selecting 'angler' and 'guide' is already selected, remove 'guide'
      if (value === 'angler' && updatedSelectedType.includes('guide')) {
        updatedSelectedType = updatedSelectedType.filter(item => item !== 'guide');
      }
      updatedSelectedType.push(value);
    }

    setSelectedType(updatedSelectedType);
    setFieldValue('team_members_type', updatedSelectedType);
  };

  return (
    <>
      <div className='members-main'>
        <div className='species-button-group'>
          <div className='page-title'>Members</div>
          <Button
            type="button"
            onClick={() => {
              setMemberModal(true);
              setSelectedImage(null); // Reset image selection
              setSelectedType([]); // Reset participant types
              setIsEditing(false);
              setCurrentEditIndex(null);
            }}
            className="add-species-button"
          >
            Add Members
          </Button>
        </div>
        <Table>
          <thead>
            <tr>
              <th>S.No</th>
              <th>Participant Name</th>
              <th>Email</th>
              <th>Participant Type</th>
              <th>Participant Team</th>
              <th>Special Category</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {members && members.length > 0 ? (
              members.map((member, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td style={{ textAlign: "left" }}>
                    {member.team_member_photo && (
                      <img src={member.team_member_photo} alt='' width="50" style={{ marginRight: '10px', objectFit: 'cover' }} />
                    )}
                    {member.team_members_name}
                  </td>
                  <td style={{ textTransform: "lowercase" }}>{member.team_members_email}</td>
                  <td>{member.team_members_type.join(', ')}</td> {/* Display multiple selected types */}
                  <td >{member.team_name}</td> {/* Display team_name */}
                  <td>{member.category_name}</td>
                  <td>
                    <Button onClick={() => handleEditRow(index)} className='edit-button'>
                      Edit
                    </Button>
                    <Button onClick={() => handleDeleteRow(index)} className='delete-button'>
                      Delete
                    </Button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="6">No members available</td>
              </tr>
            )}
          </tbody>
        </Table>

        {errorMessage &&
          <p className='species-error'>
            Please add at least one member
          </p>
        }

        <div className="species-button-group">
          <Button
            type="button"
            className="next-create-button"
            onClick={() => {
              localStorage.setItem("page", "teams"); // Update 'page' in localStorage
              navigate('/user-login/create/teams');
            }}
          >
            Back
          </Button>
          <Button
            type="button"
            className="next-create-button"
            onClick={() => {
              // Update 'page' in localStorage
              if (members.length > 0) {
                localStorage.setItem("page", "rules"); // Update 'page' in localStorage
                navigate('/user-login/create/rules');
              } else {
                setErrorMessage(true)
              }
            }}
          >
            Next
          </Button>
        </div>
      </div>

      <Modal
        show={memberModal}
        onHide={() => {
          setMemberModal(false);
          setSelectedImage(null);
          setSelectedType([]);
          setIsEditing(false);
          setCurrentEditIndex(null);
        }}
        backdrop="static"
        centered
      >
        <ModalHeader closeButton>{isEditing ? "Edit Member" : "Add Member"}</ModalHeader>
        <ModalBody>
          <Formik
            validationSchema={schema}
            initialValues={initialFormValues}
            enableReinitialize={true} // To update initialValues when editing
            onSubmit={(values, { setSubmitting, resetForm }) => {
              const updatedValues = { ...values, team_members_type: selectedType };
              if (isEditing && currentEditIndex !== null) {
                handleUpdateMembers(currentEditIndex, updatedValues);
              } else {
                handleAddMembers(updatedValues);
              }
              setSubmitting(false);
              setMemberModal(false);
              setIsEditing(false);
              setCurrentEditIndex(null);
              resetForm();
              setSelectedType([]);
              setSelectedImage(null);
            }}
          >
            {({ setFieldValue,values }) => (
              <FormikForm className="mb-3">
                {selectedImage ? (
                  <div className="image-preview mb-3" style={{ position: 'relative' }}>
                    <img src={selectedImage} alt="Selected" />
                    <FaTimes
                      className="delete-image-icon"
                      onClick={() => handleImageDelete(setFieldValue)}
                    />
                  </div>
                ) : (
                  <div className='team-image-main mb-3'>
                    <label htmlFor="team_member_photo" className='center-flex' style={{ cursor: 'pointer' }}>
                      <img src='/images/upload.png' alt="Upload" />
                      <p>Drag and Drop files, or Browse</p>
                      <p>Upload only an image up to 10MB</p>
                    </label>
                    <input
                      id="team_member_photo"
                      name="team_member_photo"
                      type="file"
                      accept="image/*"
                      style={{ display: 'none' }}
                      onChange={(e) => handleImageChange(e, setFieldValue)}
                    />
                  </div>
                )}
                <div className="details-field mb-3">
                  <label htmlFor="team_members_name">Participant Name <span style={{ color: 'red' }}>*</span></label>
                  <Field
                    type="text"
                    id="team_members_name"
                    name="team_members_name"
                    autoComplete="off"
                    maxLength="50" // Enforce max length
                    className="form-control"
                  />
                  <div className="error-wrapper mb-3">
                    <ErrorMessage name="team_members_name" component="div" className="error-message" />
                  </div>
                </div>

                <div className="details-field mb-3">
                  <label htmlFor="team_members_email">E-mail ID <span style={{ color: 'red' }}>*</span></label>
                  <Field
                    type="email" // Use email input type for better validation
                    id="team_members_email"
                    name="team_members_email"
                    autoComplete="off"
                    className="form-control"
                  />
                  <div className="error-wrapper mb-3">
                    <ErrorMessage name="team_members_email" component="div" className="error-message" />
                  </div>
                </div>

                <div className="details-field mb-3">
                  <label htmlFor="team_id">Select Team</label>
                  <Field as="select" name="team_id" id="team_id" className="form-control">
                    <option value="">Select a team</option>
                    {data?.teams?.map((team) => (
                      <option key={team._id} value={team._id}>
                        {team.team_name}
                      </option>
                    ))}
                  </Field>
                  <div className="error-wrapper mb-3">
                    <ErrorMessage name="team_id" component="div" className="error-message" />
                  </div>
                </div>

                {/* Participant Type Field */}
                <div className="details-field mb-3">
                  <label htmlFor="team_members_type">Participant Type <span style={{ color: 'red' }}>*</span></label>

                  <Dropdown className='select-type-dropdown' style={{ width: '100%' }}>
                    <Dropdown.Toggle variant="secondary" id="dropdown-basic" style={{ width: '100%' }}>
                      {selectedType.length > 0 ? selectedType.join(', ') : 'Select Participant Type'}
                    </Dropdown.Toggle>

                    <Dropdown.Menu style={{ width: '100%' }}>
                      {/* 'guide' and 'angler' are mutually exclusive */}
                      <Dropdown.Item as="div">
                        <Form.Check
                          type="checkbox"
                          label="Guide"
                          checked={selectedType.includes('guide')}
                          onChange={() => handleOptionToggle('guide', setFieldValue)}
                        />
                      </Dropdown.Item>
                      <Dropdown.Item as="div">
                        <Form.Check
                          type="checkbox"
                          label="Angler"
                          checked={selectedType.includes('angler')}
                          onChange={() => handleOptionToggle('angler', setFieldValue)}
                        />
                      </Dropdown.Item>
                      <Dropdown.Item as="div">
                        <Form.Check
                          type="checkbox"
                          label="Tournament Assistant"
                          checked={selectedType.includes('tournament_assistant')}
                          onChange={() => handleOptionToggle('tournament_assistant', setFieldValue)}
                        />
                      </Dropdown.Item>
                      <Dropdown.Item as="div">
                        <Form.Check
                          type="checkbox"
                          label="Spectator"
                          checked={selectedType.includes('spectator')}
                          onChange={() => handleOptionToggle('spectator', setFieldValue)}
                        />
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>

                  <div className="error-wrapper mb-3">
                    <ErrorMessage name="team_members_type" component="div" className="error-message" />
                  </div>
                </div>

                <div className="category-display mb-3">
                  <label htmlFor="category">Special Category</label>
                  <Field name="category" id="category">
                    {({ field, form }) => (
                      <Select
                        {...field}
                        value={data?.special_category?.find(cat => cat.title === values.category)}  // Set selected value
                        options={data?.special_category?.map(cat => ({
                          value: cat._id,
                          // label: cat.title,
                          title: cat.title,
                          description: cat.description
                        }))}
                        getOptionLabel={(e) => (
                          <div className='category-option-display'>
                            <h6>{e.title}</h6>
                            <p>{e.description}</p>
                          </div>
                        )}
                        components={{ Option: customOption }}
                        onChange={(selectedOption) => setFieldValue("category", selectedOption?.value)} // Set selected category value
                        placeholder="Select a Category"
                      />
                    )}
                  </Field>
                  <ErrorMessage name="category" component="div" className="field-error" />
                </div>

                <Button className='next-create-button' type="submit">
                  {isEditing ? "Update" : "Submit"}
                </Button>
              </FormikForm>
            )}
          </Formik>
        </ModalBody>
      </Modal>
    </>
  );
};

export default Members;


