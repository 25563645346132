import React, { useState, useEffect } from 'react';
import { Button, Table, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { IoArrowBack } from "react-icons/io5";
import "../publish.css";
import { NotificationContainer, NotificationManager } from 'react-notifications';
import appUrl from '../../../appUrl';

const Report = () => {
  const navigate = useNavigate();
  const [report, setReport] = useState([]);
  const AppUrl = appUrl();
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    getReportList();
  }, []);

  console.log(isLoading, "isLoading")
  const tournamentID = localStorage.getItem("tournament");

  const getReportList = async () => {
    setIsLoading(true)
    const fishingToken = localStorage.getItem("token");
    try {
      const response = await fetch(`${AppUrl}/api/v1/report/director?tournament_id=${tournamentID}`, {
        method: "GET",
        headers: {
          "Authorization": `Bearer ${fishingToken}`,
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();
      if (response.ok && data.data) {
        setReport(data.data);
        setIsLoading(false)
      } else {
        NotificationManager.error('Failed to fetch report list');
        setIsLoading(false)
      }
    } catch (error) {
      NotificationManager.error('Error fetching report list');
    }
  };

  console.log(report, "reports")

  return (
    <>
      <NotificationContainer />
      <div className='report-title-new'>
        <div className='d-flex report-back'>
          <IoArrowBack onClick={() => navigate("/user-login/publish")} className='back-arrow' />
          <h3>Reports</h3>
        </div>
        <div className='report-button-create'>

          <Button className='report-buttons'
            onClick={() => {
              navigate(`/user-login/report/user`)
            }}
          >
            User Report
          </Button>
          <Button className='report-buttons' onClick={() => {
            navigate(`/user-login/report/fish`);
          }}>
            Fish Report
          </Button>
          <Button className='report-buttons' onClick={() => { navigate("/user-login/publish/create-report"); localStorage.removeItem("isUpdate"); localStorage.removeItem("report") }}>
            Create New Report
          </Button>
        </div>
      </div>

      {isLoading &&
        <div className='loading-spinner' >
          <Spinner animation="border" role="status" />
        </div>
      }

      {!isLoading && report.length > 0 ? (
        <Table striped bordered hover responsive className='mt-5'>
          <thead>
            <tr>
              <th>S.No</th>
              <th>Report Name</th>
              <th>First</th>
              <th>Second</th>
              <th>Third</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {report.map((rep, index) => (
              <tr key={rep._id}>
                <td>{index + 1}</td>
                <td>{rep.report_name}</td>
                <td>{rep.result?.[0]?.participant_name || "-"}</td>
                <td>{rep.result?.[1]?.participant_name || "-"}</td>
                <td>{rep.result?.[2]?.participant_name || "-"}</td>
                <td>
                  <Button variant="success" size="sm" className="me-2" onClick={() => {
                    navigate("/user-login/publish/create-report");
                    localStorage.setItem("report", rep._id);
                    localStorage.setItem("isUpdate", true)
                  }} >Edit</Button>
                  <Button variant="danger" size="sm" className="me-2">Delete</Button>
                  <Button variant="light" size="sm" onClick={() => {
                    navigate("/user-login/publish/result");
                    localStorage.setItem("report", rep._id)
                  }}>Result</Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <p className='text-center mt-5'>You have not added any Report for this tournament. Add to proceed</p>
      )}
    </>
  );
};

export default Report;
