// src/reducer.js
import {
    SET_UP,
    SET_SPECIES,
    SET_MEMBERS,
    SET_RULES,
    SUBMIT_TOURNAMENT,
    SUBMIT_TOURNAMENT_SUCCESS,
    SUBMIT_TOURNAMENT_FAILURE,
    CLEAR_STATE
} from './actions';

const initialState = {
    setup: {},
    species: [],
    members: [],
    rules: "",
    submitting: false,
    submitSuccess: null,
    submitError: null,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        // case SET_UP:
        //     return { ...state, setup: action.payload };
        case SET_UP:
            console.log("Reducer received SET_UP with special_category:", action.payload.special_category);
            return {
                ...state,
                setup: action.payload
            };

        case SET_SPECIES:
            return { ...state, species: action.payload };
        case SET_MEMBERS:
            return { ...state, members: action.payload };
        case SET_RULES:
            return { ...state, rules: action.payload };
        case SUBMIT_TOURNAMENT:
            return { ...state, submitting: true, submitSuccess: null, submitError: null };
        // case SUBMIT_TOURNAMENT_SUCCESS:
        //     return { ...state, submitting: false, submitSuccess: action.payload };
        case SUBMIT_TOURNAMENT_SUCCESS:
            console.log("Reducer: Tournament submission success", action.payload);
            console.log("Current State before updating:", state); // Log current state
            return {
                ...state,
                submitting: false,
                submitSuccess: action.payload // Store the response data here
            };
        case SUBMIT_TOURNAMENT_FAILURE:
            return { ...state, submitting: false, submitError: action.payload };
        case CLEAR_STATE: // Handle the clear state action
            return initialState;
        default:
            return state;
    }
};

export default reducer;
